@import "./entities-list-item";

$app-entities-list-row-spacing-bottom: 1rem;

.app-entities-list {
  li {
    display: flex;
  }

  figure {
    @include entities-list-item-figure-mixin();
  }

  article {
    @include entities-list-item-article;
  }
}

.app-entities-list.app-view {
  li {
    @extend .col-12;
    @extend .col-sm-6;
    @extend .col-md-12;
    @extend .col-xl-6;

    overflow: hidden;

    figure {
      @include entities-list-item-figure-view;
    }

    article {
      @include entities-list-item-article-view;
    }
  }
}

.app-entities-list.app-manage {
  li {
    padding-bottom: $app-entities-list-row-spacing-bottom;
    padding-top: $app-entities-list-row-spacing-bottom;
    
    &:not(:last-of-type) {
      // border-bottom: 1px solid rgba(0, 0, 0, 0.125);
      border-bottom: 1px solid $gray-300;
    }

    figure {
      @include entities-list-item-figure-manage;
    }

    article {
      @include entities-list-item-article-manage;
    }
  }
}
