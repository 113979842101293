@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins";
@import '../../components/shared/adverts-list-item';

.app-advert-tile {
  figure {
    -x-t: 4;
  }
  article {
    -g-h: 0;
    header {
      @include advert-header;
    }
  }
}
