@import "../../themes/setup.scss";
@import "./entities-list";
@import "./entities-list-item";

$articlePaddgingY: 0.375em;
$articlePaddgingX: 0.75em;
$tilePadding: 0.3em;
$headerFontSize: 1.2em;
$headerLineHeight: 1.2;
$headerPaddingBottom: 0.2em;
$paragraphFontSize: 1em;
$paragraphLineHeight: 1.2;
$asidePaddginTop: 0.3em;
$borderRadius: 0.25em;

.app-new-entities-list {
  position: relative;
  padding-left: 0;
  list-style: none;

  > li {
    overflow: hidden;
    display: flex;
    position: relative;
    padding: $tilePadding;

    > figure {
      margin: 0;
      position: relative;
      flex-shrink: 0;

      // TODO Reduce elemts count
      //   &::before {
      //     content: '';
      //     position: absolute;
      //     z-index: 1;
      //     display: block;
      //     width: 30px;
      //     height: 30px;
      //     border-radius: 0.25rem;
      //     background-image: url(/images/layout/ad-icon.svg?type=lost&animal=dog);
      //     bottom: 5px;
      //     left: 5px;
      //   }

      //   &::after {
      //     content: '';
      //     position: absolute;
      //     z-index: 1;
      //     display: block;
      //     width: 50px;
      //     height: 50px;
      //     border-radius: 0.25rem;
      //     background-color: red;
      //     background-image: url(/images/layout/ad-icon.svg?type=found&animal=dog);
      //     bottom: 5px;
      //     right: 5px;
      //   }

      //   img {
      //     &::before {
      //       content: '';
      //       position: absolute;
      //       z-index: 1;
      //       display: block;
      //       width: 50px;
      //       height: 50px;
      //       border-radius: 0.25rem;
      //       background-color: blue;
      //       background-image: url(/images/layout/ad-icon.svg?type=lost&animal=dog);
      //       top: 5px;
      //       left: 5px;
      //     }

      //     &::after {
      //       content: '';
      //       position: absolute;
      //       z-index: 1;
      //       display: block;
      //       width: 30px;
      //       height: 30px;
      //       border-radius: 0.25rem;
      //       background-image: url(/images/layout/ad-icon.svg?type=found&animal=dog);
      //       top: 5px;
      //       right: 5px;
      //     }
      //   }
    }

    > article {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      overflow: hidden;
      padding: $articlePaddgingY $articlePaddgingX;

      > * {
        flex-shrink: 0;
      }

      > header {
        padding-bottom: $headerPaddingBottom;
        overflow: hidden;
        text-overflow: ellipsis;

        > h2 {
          display: inline;
          font-size: $headerFontSize;
          line-height: $headerLineHeight;
          margin: 0;

          a {
            @include nounderline-mixin();

            color: $advert-header-color;

            &:hover {
              color: $advert-header-color-hover;
            }
          }
        }
      }

      > p {
        padding: 0;
        margin: 0;
        font-size: $paragraphFontSize;
        line-height: $paragraphLineHeight;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        max-height: $paragraphFontSize * $paragraphLineHeight * 2;
        -webkit-line-clamp: 2;

        @include media-breakpoint-up(sm) {
          max-height: $paragraphFontSize * $paragraphLineHeight * 3;
          -webkit-line-clamp: 3;
        }
      }

      > aside {
        display: flex;
        justify-content: flex-end;
        margin-top: auto;
        padding-top: $asidePaddginTop !important;
      }
    }
  }
}

.app-new-entities-list-public {
  display: flex;
  flex-wrap: wrap;

  > li {
    @extend .col-12;
    @extend .col-sm-6;
    @extend .col-md-12;
    @extend .col-xl-6;

    > article {
      border: 1px solid $gray-300;
      border-radius: $borderRadius;

      > aside {
        @include adverts-list-item-app-view-article-aside();
      }
    }

    &.active {
      > article {
        background-color: $light;

        > aside {
          > button {
            @include adverts-list-item-app-view-article-aside-button-active-mixin;
          }
        }
      }
    }
  }
}

.app-new-entities-list-private {
  > li {
    padding-bottom: $app-entities-list-row-spacing-bottom;
    padding-top: $app-entities-list-row-spacing-bottom;

    &:not(:last-of-type) {
      border-bottom: 1px solid $gray-300;
    }

    > figure {
      .app-advert-info-icons {
        position: absolute;
        left: 5px;
      }
    }
  }
}

.app-new-adverts-list {
  > li {
    > figure {
      width: 131px;
      height: 132px;
      border: 1px solid $gray-300;
      border-top-left-radius: $borderRadius;
      border-bottom-left-radius: $borderRadius;
      border-right: 0;

      @include media-breakpoint-up(sm) {
        width: 151px;
        height: 152px;
      }

      > picture {
        display: block;
        height: 100%;
        background-color: $light;
        background-image: url("AssetsAlias/adverts-list-item/thumbnail-background.svg");
        background-repeat: no-repeat;
        background-position: 0, 0;

        img {
          width: 100%;
          height: 100%;
          border-top-left-radius: $borderRadius;
          border-bottom-left-radius: $borderRadius;
        }
      }
    }

    > article {
      border-left: 0;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;

      > header {
        display: -webkit-box;
        line-height: $headerLineHeight;
        max-height: $headerFontSize * $headerLineHeight * 2;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;

        time {
          @include advert-header-datetime;
        }
      }
    }
  }
}

.app-new-adverts-list-public {
  > li {
    > figure {
      --j-j: 1;
    }
  }
}

.app-new-adverts-list-private {
  > li {
    > figure {
      .app-advert-info-icons {
        bottom: 5px;
      }
    }
  }
}

.app-new-businesses-list {
  > li {
    > article {
      > header {
        white-space: nowrap;
        padding-bottom: $headerPaddingBottom;
      }
    }
  }
}

.app-new-businesses-list-public {
  > li {
    > article {
      $headerHeight: $headerFontSize * $headerLineHeight + $headerPaddingBottom;
      $paragraphHeight: $paragraphFontSize * $paragraphLineHeight * 3;
      $asideHeight: 2.2em + $asidePaddginTop;
      $articlePaddging: $articlePaddgingY * 2;

      height: calc(#{$headerHeight} + #{$paragraphHeight} + #{$asideHeight} + #{$articlePaddging});
      height: $headerHeight + $paragraphHeight + $asideHeight + $articlePaddging;
    }
  }
}

.app-new-businesses-list-private {
  > li {
    > figure {
      width: 40px;

      .app-advert-info-icons {
        top: 5px;
      }
    }

    > article {
      $headerHeight: $headerFontSize * $headerLineHeight + $headerPaddingBottom;
      $paragraphHeight: $paragraphFontSize * $paragraphLineHeight * 3;
      $asideHeight: 3.8em + $asidePaddginTop;
      $articlePaddging: $articlePaddgingY * 2;

      height: calc(#{$headerHeight} + #{$paragraphHeight} + #{$asideHeight} + #{$articlePaddging});
      height: $headerHeight + $paragraphHeight + $asideHeight + $articlePaddging;
    }
  }
}
