@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins";
@import "../shared/entities-list-item.scss";

// Helpers

@mixin advert-header-title {
  font-size: 1.1rem;

  @include media-breakpoint-up(sm) {
    font-size: 1.2rem;
  }

  display: inline;

  a {
    @include nounderline-mixin();
    color: $advert-header-color;

    &:hover {
      color: $advert-header-color-hover;
    }
  }
}

@mixin advert-header-datetime {
  font-size: 0.7rem;
  display: inline;
  font-style: italic;
  color: darken(#18bc9c, 15%);

  &::before {
    content: " / ";
  }
}

@mixin advert-header {
  padding-bottom: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-height: 1.2;
  /* fallback */
  max-height: 45px;
  /* fallback */
  -webkit-line-clamp: 2;
  /* number of lines to show */
  -webkit-box-orient: vertical;

  @include media-breakpoint-up(sm) {
    max-height: 47px;
  }

  h2 {
    @include advert-header-title;
  }

  time {
    @include advert-header-datetime;
  }
}

@mixin adverts-list-item-app-view-article-aside-button-active-mixin {
  width: auto;

  span {
    display: inline-block;
  }
}

@mixin adverts-list-item-app-view-article-aside {
  display: flex;
  justify-content: flex-end;

  button {
    width: auto;
    min-width: 2.2rem;
    height: 2.2rem;
    padding: 0.5rem;
    margin-right: -0.5rem;
    margin-bottom: -0.5rem;
    border: 0;
    outline: none;
    color: $text-muted;
    background: transparent;
    background-image: url("AssetsAlias/adverts-list-item/pin.svg");
    background-repeat: no-repeat;
    background-position: right;
    background-origin: content-box;
    background-size: contain;
    z-index: 1;

    span {
      display: none;
      line-height: 1rem;
      margin-right: 1.4rem;
      max-height: 1rem;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    &:hover {
      @include adverts-list-item-app-view-article-aside-button-active-mixin;
    }

    // i {
    //   margin-left: 0.5rem;
    //   font-size: 1.2rem;
    //   line-height: 1.1;
    // }
  }
}

// Figure

@mixin adverts-list-item-figure-mixin {
  @include entities-list-item-figure-mixin();
  width: 131px;
  height: 132px;
  flex-shrink: 0;
  position: relative;

  @include media-breakpoint-up(sm) {
    width: 151px;
    height: 152px;
  }

  picture {
    display: block;
    background-color: $light;
    background-image: url("AssetsAlias/adverts-list-item/thumbnail-background.svg");
    background-repeat: no-repeat;
    background-position: 0, 0;
  }

  img,
  picture {
    width: 100%;
    height: 100%;
    border-top-left-radius: 0.22rem;
    border-bottom-left-radius: 0.22rem;
  }

  > div {
    width: 100%;
    height: 100%;
  }

  .app-ad-icon {
    position: absolute;
    top: 5px;
    left: 2px;
    font-size: 30px;
  }

  + article {
    --ccc-c: 1;
  }
}

@mixin adverts-list-item-figure-view-mixin {
  height: 132px;
  border: 1px solid $gray-300;
  border-radius: 0.25rem 0 0 0.25rem;
  border-right: 0;

  @include media-breakpoint-up(sm) {
    height: 152px;
  }
}

@mixin adverts-list-item-figure-manage {
  .app-advert-info-icons {
    position: absolute;
    bottom: 5px;
    left: 5px;
  }
}

// Article

@mixin adverts-list-item-article-mixin {
  @include media-breakpoint-up(sm) {
    // width: calc(100% - 150px);
  }

  > header {
    @include advert-header;
  }

  > p {
    padding: 0;
    margin: 0;
    font-size: 1rem;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    line-height: 1.3;
    /* fallback */
    max-height: calc(1rem * 1.3 * 2);
    /* fallback */
    -webkit-line-clamp: 2;
    /* number of lines to show */
    -webkit-box-orient: vertical;

    @include media-breakpoint-up(sm) {
      max-height: calc(1rem * 1.3 * 3);
      /* fallback */
      -webkit-line-clamp: 3;
      /* number of lines to show */
    }
  }
}

@mixin adverts-list-item-article-view-mixin {
  // border: 1px solid rgba(0, 0, 0, 0.125);
  border: 1px solid $gray-300;
  border-radius: 0.25rem 0.25rem 0.25rem 0.25rem;
  // border-left: 0;

  // figure ~ & {
  //   --x-x: 1;
  // }

  // ~ figure & {
  //   --x-x: 1;
  // }

  > aside {
    @include adverts-list-item-app-view-article-aside;
  }
}

@mixin adverts-list-item-article-manage {}
