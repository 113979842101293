//
// App
//

$advert-icon-url: "/images/layout/ad-icon.svg";
$advert-static-marker-icon-url: "/images/layout/ad-marker-icon.svg";

// Layout

// Icon color
$avert-icon-color-lost: "f50000";
$avert-icon-color-found: "45db2e";
$avert-icon-color-adopt: "8282ec";
$avert-icon-color-default: "000000";

// Advert types
$advert-type-lost: "lost";
$advert-type-found: "found";
$advert-type-adopt: "adopt";

$advert-types: ($advert-type-lost, $advert-type-found, $advert-type-adopt);

// Advert animal
$advert-animal-dog: "dog";
$advert-animal-cat: "cat";
$advert-animal-other: "other";

$advert-animal: ($advert-animal-dog, $advert-animal-cat, $advert-animal-other);

// Advert header

$advert-header-color: rgba(0, 0, 0, 0.9);
$advert-header-color-hover: rgba(0, 0, 0, 0.7);

//
// Bootstrap
//

$white: #fff !default;
$blue: #2c3e50;
$red: #e74c3c !default;
// $green: #18bc9c !default;
$cyan: #117a8b;
$font-size-base: 0.9375rem !default;
$alert-border-width: 0;

$link-color: #33408a;
$link-decoration: none;
$link-shade-percentage: 40%;
$link-hover-decoration: underline;

// $input-color: $gray-800 !default;

// $link-color: lighten($primary, 10%);
$input-btn-focus-width: 0;
$btn-focus-box-shadow: 0;
$btn-focus-width: 0;

$font-family-sans-serif: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI",
  Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
  "Segoe UI Emoji", "Segoe UI Symbol";

$light: #f4f5f5;

$font-weight-bold: 600;

$breadcrumb-divider: '>';

$enable-dark-mode: false; // TODO Fix for bootstrap Undefined variable. "primary": $primary-text-emphasis-dark, https://github.com/twbs/bootstrap/issues/38683
