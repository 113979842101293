@import "../../../../client/styles/themes/setup";

body.home {
  @include media-breakpoint-up(md) {
    .app-main-nav {
      background-color: red;
      padding-top: 2rem !important;
      padding-bottom: 2rem !important;

      .navbar-brand {
        --x-x: 1;
        position: absolute; // to make #main-navigation ul margin-left work
      }

      #main-navigation {
        --x-x: 2;

        > ul {
          --x-x: 3;
          margin-left: auto;
          margin-right: auto;

          > li {
            --x-x: 4;

            a {
              --x-x: 5;
              font-size: 1.5rem;
              padding-left: 1.5rem;
              padding-right: 1.5rem;
            }
          }
        }
      }
    }
  }
}
