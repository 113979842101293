footer {
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  font-size: smaller;
  nav {
    a {
      margin-right: 1rem;
    }
  }
}
