@import "../../../themes/setup";

.app-main-navigation {
  .brand {
    display: flex;

    .home {
      display: flex;
      padding-right: 1rem;
      padding-top: 0.32421875rem;
      padding-bottom: 0.32421875rem;

      > span:first-of-type {
        // Icon
        width: 2.5rem;
        height: 2.5rem;
        margin-right: 0.2rem;
        background-image: url("AssetsAlias/layout/paw.svg");
      }

      > span:last-of-type {
        // Text
        width: 120px;
        height: 40px;
        display: flex;
        flex-direction: column;
        text-align: center;
        margin: 0;
        background-image: url("AssetsAlias/layout/logotype-text-only-to-web.svg");
        background-repeat: no-repeat;
      }
    }

    .love {
      margin-bottom: auto;
      margin-top: auto;
      margin-left: 0;
      margin-right: 0.4rem;
      @include media-breakpoint-up(sm) {
        margin-left: 0.5rem;
        margin-right: 1.2rem;
      }
      font-size: 1.5rem;
      line-height: 1.5rem;
      width: 1.45rem;
      height: 1.45rem;
      background-image: url("AssetsAlias/layout/heart.svg");
      background-repeat: no-repeat;
      background-size: cover;
      &:hover,
      &.active {
        background-position: bottom;
      }
    }
  }

  .navbar-collapse {
    min-width: 0;

    > ul {
      flex-grow: 1;
      min-width: 0;

      li {
        a {
          white-space: nowrap;
        }

        &:nth-child(-n + 2) {
          a {
            font-size: 1.1rem;
            font-weight: 500;
          }
        }
      }

      @include media-breakpoint-up(md) {
        align-items: center;

        li {
          &:nth-last-child(2) {
            margin-left: auto;
            min-width: 0;

            a {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }

          &:last-child {
            display: flex;

            &::before {
              padding-top: 0.5rem;
              padding-bottom: 0.5rem;
              content: "/";
            }
          }
        }
      }
    }
  }
}
