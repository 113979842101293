@import "./functions";

@mixin app-advert-icon-mixin {
  background-repeat: no-repeat;
  background-image: url(get-advert-icon-url());
  @each $typeCode in $advert-types {
    &.type-#{$typeCode} {
      background-image: url(get-advert-icon-url($typeCode));
      @each $animalCode in $advert-animal {
        &.animal-#{$animalCode} {
          background-image: url(get-advert-icon-url($typeCode, $animalCode));
        }
      }
    }
  }
}

@mixin app-advert-static-marker-icon-mixin {
  background-repeat: no-repeat;
  background-image: url(get-advert-static-marker-icon-url());
  @each $typeCode in $advert-types {
    &.type-#{$typeCode} {
      background-image: url(get-advert-static-marker-icon-url($typeCode));
      @each $animalCode in $advert-animal {
        &.animal-#{$animalCode} {
          background-image: url(get-advert-static-marker-icon-url(
            $typeCode,
            $animalCode
          ));
        }
      }
    }
  }
}

@mixin nounderline-mixin {
  text-decoration: none !important;
}
