@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins";

// Figure

@mixin entities-list-item-figure-mixin {
  margin-bottom: 0;
}

@mixin entities-list-item-figure-view {
  @include entities-list-item-figure-mixin();
  header {
    h2 {
      a {
        @extend .stretched-link;
      }
    }
  }

  + article {
    border-left: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

@mixin entities-list-item-figure-manage {
  @include entities-list-item-figure-mixin();
}

// Article

@mixin entities-list-item-article {
  --mixin-entities-list-item-article: 1;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  overflow: hidden;

  aside {
    margin-top: auto;
    text-align: right;
  }
}

@mixin entities-list-item-article-view {
  padding: $input-btn-padding-y $input-btn-padding-x;
}

@mixin entities-list-item-article-manage {
  &:not(:first-child) {
    padding-left: $app-entities-list-row-spacing-bottom;
  }
}
