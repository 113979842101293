@import "./components/footer";
@import "./components/shared/elements/render-img";
@import "./components/shared/entities-list";
@import "./components/pages/advert-tile";
@import "./components/shared/new-entities-list.scss";

// #app {
//   min-width: 295px;
// }

html {
  height: 100%;

  body {
    height: 100%;

    #app {
      height: 100%;

      main {
        min-height: calc(100% - 55.375px);
      }
    }
  }
}

.nounderline {
  @include nounderline-mixin();
}

// .noshadow {
//   box-shadow: none !important;
// }

// .link-no-color {
//   color: inherit;
//   &:hover {
//     color: inherit;
//   }
// }

.span-like-button {
  padding: 7px 13px;

  &-sm {
    padding: 4px 8px;
  }
}

// .button-like-link {
//   border: 0;
//   background: transparent;
//   padding: 0;
//   margin: 0;
//   display: inline;
//   font-weight: 100;
//   color: $link-color;
//   text-decoration: $link-decoration;
//   &:hover {
//     color: $link-hover-color;
//     text-decoration: $link-hover-decoration;
//   }
//   &:focus {
//     outline: none;
//   }
// }

// .full-height .modal-dialog {
//   .modal-body {
//     .thumbnail-modal-wrapper {
//       height: calc(100vh - 250px);
//       overflow: hidden;
//     }
//   }
// }

// @keyframes anim-rotate {
//   0% {
//     transform: rotate(0);
//   }
//   100% {
//     transform: rotate(360deg);
//   }
// }

// .spinner-border {
//   .btn & {
//     @extend .me-2;
//     width: 1.2rem;
//     height: 1.2rem;
//     border-width: 0.2em;
//   }
//   .btn-lg & {
//     width: 1.5rem;
//     height: 1.5rem;
//   }
// }

// .disabled {
//   .icon-camera,
//   .span-like-button {
//     color: #007bff;
//     opacity: 0.65;
//   }
// }

// .no-underline {
//   text-decoration: none !important;
// }

// .grecaptcha-badge {
//   visibility: hidden;
// }

// .leaflet-container {
//   font: inherit;
// }

.spinner-border-sm {
  border-width: 0.15rem;
}

// .app-input-loading-icon {
//   color: #7b8a8b;
//   top: 0.4rem;
//   right: 0.6rem;
// }

// .modal .close {
//   color: $black;
// }
