.render-img-downloading-spinner {
  display: flex;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;

  .spinner-border {
    margin: auto;
  }
}
