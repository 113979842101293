.nounderline {
  @include nounderline-mixin();
}

.link-no-color {
  color: inherit;
  &:hover {
    color: inherit;
  }
}

.span-like-button {
  padding: 7px 13px;
  &-sm {
    padding: 4px 8px;
  }
}

.button-like-link {
  border: 0;
  background: transparent;
  padding: 0;
  margin: 0;
  display: inline;
  font-weight: 300;
  color: $link-color;
  text-decoration: $link-decoration;
  &:hover {
    color: $link-hover-color;
    text-decoration: $link-hover-decoration;
  }
  &:focus {
    outline: none;
  }
}

.full-height .modal-dialog {
  .modal-body {
    .thumbnail-modal-wrapper {
      height: calc(100vh - 250px);
      overflow: hidden;
    }
  }
}

@keyframes anim-rotate {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

.disabled {
  .icon-camera,
  .span-like-button {
    color: #007bff;
    opacity: 0.65;
  }
}

.no-underline {
  text-decoration: none !important;
}

.grecaptcha-badge {
  visibility: hidden;
}

.leaflet-container {
  font: inherit;
}

.spinner-border-sm {
  border-width: 0.15rem;
}

.app-input-loading-icon {
  color: #7b8a8b;
  top: 0.4rem;
  right: 0.6rem;
}

.modal .btn-close {
  color: $black;
}

dt {
  font-weight: 500;
}

strong {
  font-weight: 500;
}

.alert {
  overflow: hidden;

  span {
    // TODO display block?
    overflow: hidden;
  }
}

small + .invalid-feedback {
  margin-top: 0;
}
